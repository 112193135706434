import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Role } from 'types/schema.type';
import { useGetSharedTokens } from './actions/useGetSharedTokens';
import { useCreateSharedToken } from './actions/useCreateSharedToken';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  expiredLink: {
    color: `${theme.palette.error.main} !important`,
  },
  link: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
  },
}));

export function SharedLinks({ user }: { user: any }) {
  const classes = useStyles();

  const { tokens, loading } = useGetSharedTokens();
  const { createSharedToken, deleteSharedToken } = useCreateSharedToken();

  if (user.role === Role.Clinic) {
    return null;
  }

  if (loading || !tokens) {
    return <div>Loading...</div>;
  }

  const baseURL = window.location.origin;

  const isExpired = (expiresAt: Date): boolean => new Date(expiresAt) < new Date();

  const shouldBeDisabled = user.role === Role.Admin || loading || tokens.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Typography>Shared links:</Typography>

        {!tokens.length && <Typography>No shared links yet</Typography>}

        {tokens.map((token) => {
          const expired = isExpired(new Date(token.expiresAt));

          return (
            <div key={token.id} className={classes.linkContainer}>
              <Tooltip
                title={expired ? 'This link has expired' : 'This link is active'}
                placement="top"
                arrow
              >
                <Typography className={`${classes.link} ${expired ? classes.expiredLink : ''}`}>
                  {baseURL}?sharedToken={token.token}
                </Typography>
              </Tooltip>
              <IconButton
                aria-label="delete"
                onClick={() => deleteSharedToken({ variables: { tokenId: token.id } })}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        })}

        <Button
          variant="contained"
          color="primary"
          disabled={shouldBeDisabled}
          fullWidth
          onClick={() => {
            createSharedToken();
          }}
        >
          Generate a shared link
        </Button>
      </div>
    </div>
  );
}
