import { useMutation, gql } from '@apollo/client';

const CREATE_SHARED_TOKEN = gql`
  mutation CreateSharedToken {
    createSharedToken {
      error
      success
      token {
        createdAt
        expiresAt
        id
        token
        userId
      }
    }
  }
`;

const DELETE_SHARED_TOKEN = gql`
  mutation DeleteSharedToken($tokenId: ID!) {
    deleteSharedToken(tokenId: $tokenId) {
      success
      error
    }
  }
`;

export function useCreateSharedToken() {
  const [createSharedToken, { data, loading, error }] = useMutation(CREATE_SHARED_TOKEN, {
    refetchQueries: ['GetSharedTokens'],
  });

  const [deleteSharedToken] = useMutation(DELETE_SHARED_TOKEN, {
    refetchQueries: ['GetSharedTokens'],
  });

  return {
    createSharedToken,
    deleteSharedToken,
    data: data?.createSharedToken,
    loading,
    error,
  };
}
